
import { httpGet, httpPost } from '@/api';
import { ajaxLoading, erpConfirm, errorMessage, setTitleLabel, showFiles, successMessage } from '@/utils/publicMethods';
import TableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { defineComponent, ref, computed } from 'vue';
import useFormData from './usePieceFormData';
type PartListType = {
  labelId: number | string | null;
  nameCn: number | string | null;
  nameEn: number | string | null;
  packageId: number | string | null;
  rate: number | string | null;
  statusDesc: number | string | null;
  url: string | null;
};
type arrType = {
  plName: number | string | null;
  count: number | string | null;
};
type rateListType = number | string;
export default defineComponent({
  components: { TableFileContent },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const categoryRef = ref();
    const countryRef = ref();
    const plantnoRef = ref();
    const params: any = {};
    if (props.formType === 2) {
      // params = Object.assign({ operateType: 2 }, props.data);
      // let data = props.data as any;
    }
    const {
      rules,
      formData,
      formRef,
      setFormData,
      plantNoList,
      countryList,
      isfocous,
      focusindex,
      searchList,
      changblur,
      changerate,
      delindex,
      committype,
      focuslabel,
    } = useFormData(params);
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.attachmentDtos = [
          {
            attachmentType: null,
            eventId: null,
            name: fileList[0].name,
            url: fileList[0].url,
            operateType: 1,
            source: null,
          },
        ];
      } else {
        formData.attachmentDtos = null;
      }
      formRef.value.validate();
    };
    // 是否禁止编辑Fn
    const isDisabledFn = (type: number, arr: Array<number>) => {
      return arr.includes(type);
    };
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    // 部门组件对象
    const principalRef = ref();
    const changePool = (value) => {
      formData.isInPool = value;
      if (value === 1) {
        principalRef.value.clear();
      }
      formRef.value.clearValidate('principalId');
    };
    const hasDetail = computed(() => {
      let result = false;
      if (formData.partsList.length > 0) {
        result = formData.partsList.some((item) => {
          return Boolean(item.nameCn) || Boolean(item.nameEn);
        });
      }
      return result;
    });

    const close = (status = false) => {
      ctx.emit('close', status);
    };

    const querycountry = async (val, isBoolean) => {
      plantNoList.value = [];
      countryList.value = [];
      const params = {
        category: val,
      };
      const res = await httpPost('/malicrm/approval/queryCountry', params);
      if (res.code === 200) {
        countryList.value = res.result;
        if (isBoolean) {
          formData.country = null;
          formData.plantNo = null;
        }
      } else {
        errorMessage(res.message);
      }
    };
    // 大类 国家 搜索 厂号
    const queryPlantNo = async (val, isBoolean) => {
      const params = {
        category: formData.category,
        country: val,
      };
      const res = await httpPost('/malicrm/approval/queryPlantNo', params);
      if (res.code === 200) {
        plantNoList.value = res.result;
        if (isBoolean) {
          formData.plantNo = null;
        }
      } else {
        errorMessage(res.message);
      }
    };
    const addProduct = () => {
      const obj = {
        labelId: '',
        nameCn: '',
        nameEn: '',
        packageId: 0,
        rate: '',
        statusDesc: '',
        url: '',
        specificationAttachmentDtos: [],
        productPictureAttachmentDtos: [],
      };
      formData.partsList.push(obj);
    };
    const focusnameCn = (index) => {
      console.log('index', index);
      isfocous.value = false;
      focusindex.value = index;
    };
    const nameFocus = (item) => {
      setTimeout(() => {
        if (!item.labelId && !item.packageId) {
          item.nameCn = null;
          item.nameEn = null;
        } else {
          return false;
        }
      }, 200);
      console.log('列表');
      console.log(formData.partsList);
    };
    const queryNameCnSearchAsyncCn = async (queryNameCn, cb) => {
      const params = {
        category: formData.category, // 1 ,
        country: formData.country, // 19,
        nameCn: queryNameCn, // that.productForm.nameCn,
        nameEn: '', // that.productForm.nameEn,
        plantNo: [formData.plantNo], // plantNo
      };
      const res = await httpPost('/malicrm/offer/queryOfferProductLabel', params);
      if (res.code === 200) {
        searchList.value = res.result;
        if (searchList.value.length > 0) {
          searchList.value.forEach((item, index) => {
            (searchList.value[index] as any).value = (searchList.value[index] as any).nameCn;
          });
        }
        cb(searchList.value);
      } else {
        errorMessage(res.message);
      }
    };
    const clearNameLabel = (item, index) => {
      formData.partsList.splice(
        index,
        1,
        Object.assign({}, item, {
          packageId: null,
          labelId: null,
          nameCn: null,
          nameEn: null,
        })
      );
    };
    const removeProduct = (index) => {
      delindex.value = index;
      if (formData.partsList[index].nameCn || formData.partsList[index].nameEn || formData.partsList[index].rate) {
        erpConfirm('确定删除吗？').then(async () => {
          committype.value = '1';
          formData.partsList.splice(delindex.value, 1);
        });
      } else {
        formData.partsList.splice(index, 1);
      }
    };
    // 查看标签
    const chacklable = (item, index, num) => {
      console.log(item, index, num);
      if (num === 1) {
        window.open(item.url);
      } else if (num === 2) {
        showFiles(item.specificationAttachmentDtos[0].name, item.specificationAttachmentDtos[0].url);
      } else {
        showFiles(item.productPictureAttachmentDtos[0].name, item.productPictureAttachmentDtos[0].url);
      }
    };
    // 中文品名 选择事件
    const nameSelect = (item) => {
      console.log('item', item);
      formData.partsList[focusindex.value].nameCn = item.nameCn;
      formData.partsList[focusindex.value].nameEn = item.nameEn;
      formData.partsList[focusindex.value].labelId = item.id;
      formData.partsList[focusindex.value].url = item.url;
      formData.partsList[focusindex.value].productPictureAttachmentDtos = item.productPictureAttachmentDtos;
      formData.partsList[focusindex.value].specificationAttachmentDtos = item.specificationAttachmentDtos;
      formData.partsList[focusindex.value].isdell = true;
    };
    const submitData = async (data) => {
      const params = {
        ...formData,
      };
      params.partsList = data;
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/label/saveProductPackage', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('保存成功');
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    const categoryClick = (data): void => {
      // plantnoRef.value.blur();
      if (!hasDetail.value || props.formType === 3) {
        return;
      }
      if (formData.partsList.length) {
        categoryRef.value.customBlur();
        countryRef.value.customBlur();
        plantnoRef.value.blur();
        focuslabel.value = data;
        erpConfirm('如果修改此项，会删除部位明细里的所有品名，确定修改吗？').then(async () => {
          formData.partsList = [];
          if (countryList.value.length <= 1) {
            querycountry(formData.category, false);
          }
          if (plantNoList.value.length <= 1) {
            queryPlantNo(formData.country, false);
          }
        });
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          // formData.signDate = formatTime(formData.signDate, 'YYYY-MM-DD');
          const arrlist: arrType[] = [];
          const ratelist: rateListType[] = [];
          const baselist: PartListType[] = [];
          for (let q = 0; q < formData.partsList.length; q++) {
            if (formData.partsList[q].nameEn || formData.partsList[q].nameCn || formData.partsList[q].rate) {
              // this.errorTip('请填写所有带星号的必填项');
              baselist.push(formData.partsList[q] as any);
              // continue;
            }
          }
          const database: PartListType[] = baselist;
          if (!database.length) {
            errorMessage('请填写所有带星号的必填项');
            return;
          }
          for (let index = 0; index < database.length; index++) {
            // const element = database[index];
            if (!(database[index] as any).nameEn || !(database[index] as any).nameCn || !(database[index] as any).rate) {
              errorMessage('请填写所有带星号的必填项');
              return;
            }
            arrlist.push((database[index] as any).nameEn);
            ratelist.push((database[index] as any).rate);
          }
          // 英文品名存在重复
          const arr: arrType[] = [];
          arrlist.sort(); // 注意，一定要排序，不排序，会重复判断，从而错误
          for (let i = 0; i < arrlist.length; ) {
            // 按照属性判断属性合并列数
            let count = 0;
            for (let j = i; j < arrlist.length; j++) {
              if (arrlist[i] === arrlist[j]) {
                count++;
              }
            }
            arr.push({
              plName: arrlist[i] as any,
              count,
            });
            i += count;
          }
          for (let k = 0; k < arr.length; k++) {
            if ((arr[k] as any).count > 1) {
              errorMessage('部位明细里的英文品名存在重复');
              return;
            }
          }
          let istrue = 0;
          for (let q = 0; q < ratelist.length; q++) {
            istrue = istrue + parseFloat(ratelist[q] as any);
          }
          istrue = Number(istrue.toFixed(2));
          if (istrue !== 100) {
            errorMessage('部位的比例之和不等于100' + '，实际值为：' + istrue);
            return;
          }
          submitData(database);
        } else {
          return false;
        }
      });
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`/malicrm/label/getProductPackageByOfferId/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        formData.partsList = res.result.partsList;
        const countOpt = {
          country: res.result.country,
          countryName: res.result.countryDesc,
        };
        console.log('formData', formData);
        console.log('formData.partsList', formData.partsList);
        console.log('countOpt', countOpt);
        (countryList.value as any).push(countOpt);
        // console.log('countryList', countryList);
      } else {
        errorMessage(res.message);
      }
    };
    if (props.formType !== 1) {
      // const feeObj = JSON.parse(JSON.stringify(props.data));
      // setFormData(feeObj.value);
      // formRef.value.validateField('settleType');
      const data = props.data as any;
      console.log('data', props.data);
      console.log('data', data.productPackageId);
      // let id = data.id;
      initData(data.productPackageId);
    }
    console.log(props.formType);
    return {
      close,
      formData,
      categoryClick,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      changePool,
      principalRef,
      getFileUrls,
      plantNoList,
      initData,
      readOnly,
      isDisabledFn,
      querycountry,
      countryList,
      queryPlantNo,
      addProduct,
      focusnameCn,
      isfocous,
      focusindex,
      nameFocus,
      queryNameCnSearchAsyncCn,
      searchList,
      clearNameLabel,
      changblur,
      changerate,
      removeProduct,
      delindex,
      committype,
      nameSelect,
      hasDetail,
      categoryRef,
      countryRef,
      plantnoRef,
      focuslabel,
      chacklable,
    };
  },
});
