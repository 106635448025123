import { reactive, ref, toRefs } from 'vue';
type FormData = {
  category: string | number | null;
  country: string | number | null;
  id: string | number;
  partsList: any[];
  plantNo: string | null;
  nameCn: string | null;
  nameEn: string | null;
  version: string | null;
  [propName: string]: any;
};
type countryObjType = {
  country: string | number | null;
  countryName: string | null;
};
export default (params) => {
  const formRef = ref();
  const formData: FormData = reactive({
    category: null,
    country: null,
    id: null,
    partsList: [
      {
        labelId: '',
        nameCn: '',
        nameEn: '',
        packageId: 0,
        rate: '',
        statusDesc: '',
        productPictureAttachmentDtos: [],
        specificationAttachmentDtos: [],
        url: '',
        varietyName: '',
      },
    ],
    plantNo: null,
    nameCn: null,
    nameEn: null,
    version: null,
    ...params,
  });
  const customFormData = reactive({
    plantNoList: [],
    countryList: [],
    searchList: [],
    isfocous: false,
    focusindex: 0,
    delindex: 0,
    committype: '',
    focuslabel: '',
  });
  const rules = reactive({
    category: [
      {
        required: true,
        message: '请选择大类',
        trigger: 'change',
      },
    ],
    country: [
      {
        required: true,
        message: '请选择国家',
        trigger: ['blur', 'change'],
      },
    ],
    plantNo: [
      {
        required: true,
        message: '请选择厂号',
        trigger: ['blur', 'change'],
      },
    ],
    nameCn: [
      {
        required: true,
        message: '请输入中文件套名',
        trigger: 'blur',
      },
    ],
    nameEn: [
      {
        required: true,
        message: '请输入英文件套名',
        trigger: 'blur',
      },
    ],
  });
  const changblur = (index) => {
    if (!Number(formData.partsList[index].rate)) {
      formData.partsList[index].rate = '';
    }
  };
  // 校验比率
  const changerate = (index) => {
    if (Number(formData.partsList[index].rate) >= 100) {
      formData.partsList[index].rate = '';
      return;
    }
    let value = formData.partsList[index].rate;
    value = value.replace(/^(\\d|[0-9]\\d|100)$/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    const reg = /^(\.*)(\d+)(\.?)(\d{0,2}).*$/g;
    if (reg.test(value)) {
      // 正则匹配通过，提取有效文本
      value = value.replace(reg, '$2$3$4');
    }
    // 只能输入两个小数
    formData.partsList[index].rate = value;
  };

  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    changblur,
    changerate,
  };
};
