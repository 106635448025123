import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, successMessage, errorMessage } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'signSelect',
      prop: 'category',
      value: '',
      options: 'goods_category_label',
      width: 180,
      placeholder: '请选择大类',
    },
    {
      type: 'input',
      prop: 'country',
      value: '',
      placeholder: '请输入原产国',
    },
    {
      type: 'input',
      prop: 'plantNo',
      value: '',
      placeholder: '请输入厂号',
    },
    {
      type: 'input',
      prop: 'nameCn',
      value: '',
      placeholder: '请输入中文件套名',
    },
    {
      type: 'input',
      prop: 'nameEn',
      value: '',
      placeholder: '请输入英文件套名',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '大类',
      prop: 'categoryDesc',
      width: 92,
    },
    {
      label: '原产国',
      prop: 'countryDesc',
      width: 108,
    },
    {
      label: '厂号',
      prop: 'plantNo',
      width: 206,
    },
    {
      label: '中文件套名',
      prop: 'nameCn',
      width: 251,
    },
    {
      label: '英文件套名',
      prop: 'nameEn',
      minWidth: 280,
    },
    {
      label: '关联品名数',
      prop: 'labelCount',
      minWidth: 126,
    },
    {
      label: '创建人',
      prop: 'creator',
      width: 145,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      width: 180,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button v-permission="['crm:label:saveProductPackage']" type="primary" plain @click.stop="edit(row)">编辑</el-button>
            <el-button v-permission="['crm:label:deleteProductPackage']" type="info" plain @click="deleteRow(row)">删除</el-button>

          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.setRowData(row);
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定删除？')
              .then(async () => {
                const res = await httpPost('/malicrm/label/deleteProductPackage', {
                  id: row.productPackageId,
                  version: row.version,
                });
                if (res.code === 200) {
                  successMessage('已删除');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      fixed: 'right',
      width: 200,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
